import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';

const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dbsrsfkpx/image/upload';
const CLOUDINARY_UPLOAD_PRESET = 'fiveasideapp'; // Replace with your unsigned upload preset

const RegisterPlayer = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [position, setPosition] = useState('');
  const [skillLevel, setSkillLevel] = useState(1);
  const [profilePicture, setProfilePicture] = useState(null);
  const [error, setError] = useState('');

  const uploadImageToCloudinary = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

    try {
      const response = await axios.post(CLOUDINARY_URL, formData);
      return response.data.secure_url; // Returns the URL of the uploaded image
    } catch (err) {
      console.error('Error uploading image:', err);
      return null;
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      let profilePictureURL = '';
      if (profilePicture) {
        profilePictureURL = await uploadImageToCloudinary(profilePicture);
      }
  
      await setDoc(doc(db, 'players', user.uid), {
        uid: user.uid,
        name: name,
        position: position,
        skillLevel: skillLevel,
        isTeamManager: false, // Default to false since we removed the checkbox
        profilePictureURL: profilePictureURL // Save profile picture URL in Firestore
      });

      // Redirect to the dashboard
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Error registering player:', error);
      setError('Failed to register. Please check your details and try again.');
    }
  };

  return (
    <div>
      <h2>Player Registration</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleRegister}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="text"
          placeholder="Position"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
        />
        <input
          type="number"
          placeholder="Skill Level (1-5)"
          value={skillLevel}
          onChange={(e) => setSkillLevel(Number(e.target.value))}
        />
        
        {/* File input for profile picture */}
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setProfilePicture(e.target.files[0])}
        />
        
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default RegisterPlayer;
