import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import RegisterPlayer from './components/RegisterPlayer';
import TeamRegistration from './components/TeamRegistration';
import JoinTeam from './components/JoinTeam';
import Dashboard from './components/Dashboard';
import CreateGame from './components/CreateGame';
import EditGame from './components/EditGame';
import TeamManagement from './components/TeamManagement';
import Navbar from './components/Navbar';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import './App.css';

const App = () => {
  const [user, setUser] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [preloaderVisible, setPreloaderVisible] = useState(false);

  useEffect(() => {
    // Trigger preloader fade-in on initial load
    setTimeout(() => {
      setPreloaderVisible(true);
    }, 100); // Small delay for initial fade-in

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);

      setTimeout(() => {
        setIsFadingOut(true); // Start fade-out animation
        setTimeout(() => {
          setAuthChecked(true); // Mark auth as checked
          setContentVisible(true); // Start fading in content
        }, 500); // Allow time for preloader fade-out
      }, 100); // Short delay before starting fade-out
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      {!authChecked && (
        <div className={`preloader-container ${preloaderVisible ? 'fade-in' : ''} ${isFadingOut ? 'fade-out' : ''}`}>
          <div className="preloader">
            <div class="loader"></div>
          </div>
        </div>
      )}
      <div className={`content-container ${contentVisible ? 'fade-in' : ''}`}>
        {authChecked && (
          <Router>
            <ConditionalNavbar user={user} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
              <Route path="/register" element={user ? <Navigate to="/dashboard" /> : <RegisterPlayer />} />
              {/* Protected Routes */}
              <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/login" />} />
              <Route path="/team-registration" element={user ? <TeamRegistration /> : <Navigate to="/login" />} />
              <Route path="/join-team" element={user ? <JoinTeam /> : <Navigate to="/login" />} />
              <Route path="/create-game" element={user ? <CreateGame /> : <Navigate to="/login" />} />
              <Route path="/edit-game/:gameId" element={user ? <EditGame /> : <Navigate to="/login" />} />
              <Route path="/team-management/:teamId" element={user ? <TeamManagement /> : <Navigate to="/login" />} />
            </Routes>
          </Router>
        )}
      </div>
    </>
  );
};

const ConditionalNavbar = ({ user }) => {
  const location = useLocation();
  const isHomepage = location.pathname === '/';
  return <Navbar user={user} isHomepage={isHomepage} />;
};

export default App;
