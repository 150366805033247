import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc, arrayRemove, arrayUnion, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from './Skeleton';

const TeamManagement = () => {
  const { teamId } = useParams();
  const [team, setTeam] = useState(null);
  const [membersDetails, setMembersDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) throw new Error('You must be logged in to manage a team.');

        const teamRef = doc(db, 'teams', teamId);
        const teamSnap = await getDoc(teamRef);

        if (!teamSnap.exists()) throw new Error('Team not found.');

        const teamData = teamSnap.data();
        if (!teamData.members.includes(user.uid)) throw new Error('You are not authorized to manage this team.');

        setTeam({ id: teamSnap.id, ...teamData });

        // Fetch detailed information for team members
        const membersQuery = query(collection(db, 'players'), where('uid', 'in', teamData.members));
        const membersSnapshot = await getDocs(membersQuery);
        const members = membersSnapshot.docs.map((doc) => doc.data());
        setMembersDetails(members);
      } catch (err) {
        console.error('Error fetching team data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, [teamId]);

  const handleApprove = async (userId) => {
    try {
      const teamRef = doc(db, 'teams', teamId);

      // Add the user to the `members` array and remove them from `joinRequests`
      await updateDoc(teamRef, {
        members: arrayUnion(userId),
        joinRequests: arrayRemove(userId),
      });

      setTeam((prevTeam) => ({
        ...prevTeam,
        members: [...prevTeam.members, userId],
        joinRequests: prevTeam.joinRequests.filter((id) => id !== userId),
      }));

      // Fetch and add the new member's details
      const newMemberRef = doc(db, 'players', userId);
      const newMemberSnap = await getDoc(newMemberRef);
      if (newMemberSnap.exists()) {
        setMembersDetails((prevDetails) => [...prevDetails, newMemberSnap.data()]);
      }
    } catch (err) {
      console.error('Error approving request:', err);
      setError('Failed to approve the request. Please try again.');
    }
  };

  const handleReject = async (userId) => {
    try {
      const teamRef = doc(db, 'teams', teamId);

      // Remove the user from the `joinRequests` array
      await updateDoc(teamRef, {
        joinRequests: arrayRemove(userId),
      });

      setTeam((prevTeam) => ({
        ...prevTeam,
        joinRequests: prevTeam.joinRequests.filter((id) => id !== userId),
      }));
    } catch (err) {
      console.error('Error rejecting request:', err);
      setError('Failed to reject the request. Please try again.');
    }
  };

  const handleRemoveMember = async (userId) => {
    try {
      if (!window.confirm('Are you sure you want to remove this member from the team?')) {
        return;
      }

      const teamRef = doc(db, 'teams', teamId);

      // Remove the user from the `members` array
      await updateDoc(teamRef, {
        members: arrayRemove(userId),
      });

      setTeam((prevTeam) => ({
        ...prevTeam,
        members: prevTeam.members.filter((id) => id !== userId),
      }));

      setMembersDetails((prevDetails) => prevDetails.filter((member) => member.uid !== userId));
    } catch (err) {
      console.error('Error removing member:', err);
      setError('Failed to remove the member. Please try again.');
    }
  };

  if (loading) {
    return (
      <div>
        <h2>Loading Team...</h2>
        <Skeleton width="200px" height="30px" />
        <Skeleton width="80%" height="20px" />
        <Skeleton width="100%" height="20px" />
      </div>
    );
  }

  if (error) return <p style={{ color: 'red' }}>{error}</p>;

  const isManager = team.managerId === auth.currentUser.uid;

  return (
    <div>
      <h2>Team Management: {team.name}</h2>

      <h3>Current Members</h3>
      {membersDetails.length > 0 ? (
        <ul>
          {membersDetails.map((member) => (
            <li key={member.uid} style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
              <img
                src={member.profilePictureURL || 'https://via.placeholder.com/50'}
                alt={`${member.name}'s profile`}
                style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '15px' }}
              />
              <div>
                <strong>{member.name}</strong> <br />
                <small>Position: {member.position || 'Not specified'}</small>
              </div>
              {isManager && member.uid !== team.managerId && (
                <button
                  onClick={() => handleRemoveMember(member.uid)}
                  style={{ marginLeft: '10px', color: 'red' }}
                >
                  Remove
                </button>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p>No members in the team yet.</p>
      )}

      <h3>Join Requests</h3>
      {team.joinRequests && team.joinRequests.length > 0 ? (
        <ul>
          {team.joinRequests.map((userId) => (
            <li key={userId} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <strong>{userId}</strong>
              <button onClick={() => handleApprove(userId)} style={{ marginLeft: '10px' }}>
                Approve
              </button>
              <button onClick={() => handleReject(userId)} style={{ marginLeft: '10px' }}>
                Reject
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No join requests at the moment.</p>
      )}

      <button onClick={() => navigate('/dashboard')} style={{ marginTop: '20px' }}>
        Back to Dashboard
      </button>
    </div>
  );
};

export default TeamManagement;
