import React, { useState } from 'react';
import { db, auth } from '../firebase';
import { query, collection, getDocs, where, doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';

const JoinTeam = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [teams, setTeams] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setError('');
    setTeams([]);
    setLoading(true);

    try {
      const teamsQuery = query(
        collection(db, 'teams'),
        where('name', '>=', searchTerm),
        where('name', '<=', searchTerm + '\uf8ff') // Firestore text search
      );
      const querySnapshot = await getDocs(teamsQuery);

      const matchedTeams = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTeams(matchedTeams);
    } catch (err) {
      console.error('Error searching for teams:', err);
      setError('Failed to search for teams. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleRequestJoin = async (teamId) => {
    setError('');
    setSuccess('');

    try {
      const user = auth.currentUser;
      if (!user) throw new Error('You must be logged in to request to join a team.');

      const teamRef = doc(db, 'teams', teamId);

      // Ensure `joinRequests` array exists
      const teamDoc = await getDoc(teamRef);
      if (!teamDoc.exists()) {
        throw new Error('Team does not exist.');
      }

      const teamData = teamDoc.data();
      if (!teamData.joinRequests) {
        // Initialize `joinRequests` if it doesn’t exist
        await updateDoc(teamRef, { joinRequests: [] });
      }

      // Add the user's UID to the team's `joinRequests` array
      await updateDoc(teamRef, {
        joinRequests: arrayUnion(user.uid), // Add the user's UID to the joinRequests array
      });

      setSuccess('Your request to join the team has been sent!');
    } catch (err) {
      console.error('Error sending join request:', err);
      setError('Failed to send join request. Please try again.');
    }
  };

  return (
    <div>
      <h2>Join a Team</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}

      {/* Search box */}
      <div>
        <input
          type="text"
          placeholder="Search for teams..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch} disabled={!searchTerm || loading}>
          {loading ? 'Searching...' : 'Search'}
        </button>
      </div>

      {/* Search results */}
      {teams.length > 0 && (
        <div>
          <h3>Search Results</h3>
          <ul>
            {teams.map((team) => (
              <li key={team.id} style={{ marginBottom: '1rem' }}>
                <strong>{team.name}</strong>
                <br />
                {team.logo && <img src={team.logo} alt={`${team.name} logo`} style={{ width: '50px', height: '50px' }} />}
                <br />
                <button onClick={() => handleRequestJoin(team.id)}>Request to Join</button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {teams.length === 0 && !loading && searchTerm && <p>No teams found. Try another search term.</p>}
    </div>
  );
};

export default JoinTeam;
