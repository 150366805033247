import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // For custom styling (optional)

const Home = () => {
  return (
    <div className="home-container">
      <header className="hero-section">
      <img src="/images/homepage-hero.png" alt="Homepage Hero" className="hero-image" />
        <h1>Free, simple, football team organiser</h1>
        <p>
          Create a team, invite your mates, arrange games and start kicking balls.
        </p>
        <div className="cta-buttons">
          <Link to="/register" className="cta-button primary full-width">Get organised</Link>
        </div>
      </header>
      <section className="download-app">

      </section>
      <section className="features-section">
        <h2>Why Choose fiveaside.app?</h2>
        <div className="features">
          <div className="feature">
            <h3>Organize Games Effortlessly</h3>
            <p>Create matches, invite players, and manage your team all in one place.</p>
          </div>
          <div className="feature">
            <h3>Team Management</h3>
            <p>Keep track of your team’s members, positions, and performance seamlessly.</p>
          </div>
          <div className="feature">
            <h3>Join and Compete</h3>
            <p>Search for teams near you, send join requests, and start playing in minutes.</p>
          </div>
        </div>
      </section>

      <section>

      </section>

      <section className="how-it-works">
        <h2>How It Works</h2>
        <ol>
          <li><strong>Register:</strong> Create an account and set up your profile.</li>
          <li><strong>Build a Team:</strong> Organize a team or join one that fits your style.</li>
          <li><strong>Start Playing:</strong> Schedule matches, track stats, and have fun!</li>
        </ol>
      </section>

      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} fiveaside.app. All rights reserved.</p>
        <p>
          <Link to="/about">About Us</Link> | <Link to="/contact">Contact</Link>
        </p>
      </footer>
    </div>
  );
};

export default Home;
