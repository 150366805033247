import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { addDoc, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dbsrsfkpx/image/upload';
const CLOUDINARY_UPLOAD_PRESET = 'fiveasideapp'; // Replace with your unsigned preset

const TeamRegistration = () => {
  const [teamName, setTeamName] = useState('');
  const [teamLogo, setTeamLogo] = useState(null); // Store the image file instead of URL
  const [selectionType, setSelectionType] = useState('');
  const [teamDescription, setTeamDescription] = useState(''); // New field for team description
  const [managerId, setManagerId] = useState('');
  const [members, setMembers] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate(); 

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setManagerId(currentUser.uid);
      setMembers([currentUser.uid]); // Store the UID directly in members array
    } else {
      setError('You must be logged in to register a team.');
    }
  }, []);

  const uploadImageToCloudinary = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

    try {
      const response = await axios.post(CLOUDINARY_URL, formData);
      return response.data.secure_url; // Returns the URL of the uploaded image
    } catch (err) {
      console.error('Error uploading image:', err);
      return null;
    }
  };

  const handleTeamRegistration = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      // Upload the team logo to Cloudinary if a file is selected
      let teamLogoURL = '';
      if (teamLogo) {
        teamLogoURL = await uploadImageToCloudinary(teamLogo);
      }

      // Add the team to Firestore, including selection type, description, and correct members array
      await addDoc(collection(db, 'teams'), {
        name: teamName,
        logo: teamLogoURL,
        managerId: managerId,
        members: members,
        games: [],
        selectionType: selectionType,
        description: teamDescription, // Save the team description in Firestore
        joinRequests: [] // Add an empty array for join requests
      });

      setSuccess('Team registered successfully!');
      setTeamName('');
      setTeamLogo(null);
      setSelectionType('');
      setTeamDescription('');

      // Redirect to the dashboard after a short delay
      setTimeout(() => navigate('/dashboard'), 1000);

    } catch (error) {
      console.error('Error registering team:', error);
      setError('Failed to register team. Please try again.');
    }
  };

  return (
    <div>
      <h2>Team Registration</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
      <form onSubmit={handleTeamRegistration}>
        <input
          type="text"
          placeholder="Team Name"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          required
        />

        {/* File input for team logo */}
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setTeamLogo(e.target.files[0])}
        />
        
        {/* Dropdown for selection type */}
        <label>
          Team Selection Type:
          <select
            value={selectionType}
            onChange={(e) => setSelectionType(e.target.value)}
            required
          >
            <option value="" disabled>Select an option</option>
            <option value="Fair">Fair</option>
            <option value="Random">Random</option>
            <option value="First come first served">First come first served</option>
            <option value="Repeat">Repeat</option>
            <option value="Manager picks">Manager picks</option>
          </select>
        </label>

        {/* New input for team description */}
        <textarea
          placeholder="Describe your team (e.g., 'We are friendly group of friends who love football and competition. PM for more info!')"
          value={teamDescription}
          onChange={(e) => setTeamDescription(e.target.value)}
          rows="4"
          style={{ width: '100%', marginTop: '10px', padding: '8px', fontSize: '16px' }}
        />

        <button type="submit">Register Team</button>
      </form>
    </div>
  );
};

export default TeamRegistration;
