// src/components/Skeleton.js
import React from 'react';

const Skeleton = ({ width = '100%', height = '20px', style = {}, borderRadius = '4px' }) => {
  return (
    <div
      style={{
        backgroundColor: '#e0e0e0',
        borderRadius: borderRadius,
        width: width,
        height: height,
        marginBottom: '10px',
        animation: 'skeleton-pulse 1.5s ease-in-out infinite',
        ...style,
      }}
      className="skeleton"
    ></div>
  );
};

// Circular Skeleton Component
export const SkeletonCircle = ({ size = '50px', style = {} }) => (
  <Skeleton
    width={size}
    height={size}
    borderRadius="50%"
    style={style}
  />
);

export default Skeleton;

