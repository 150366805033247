import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonCircle } from './Skeleton'; // Import Skeleton components

const Dashboard = () => {
  const [isTeamManager, setIsTeamManager] = useState(false);
  const [userTeams, setUserTeams] = useState([]);
  const [userGames, setUserGames] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const playerDocRef = doc(db, 'players', user.uid);
          const playerDocSnap = await getDoc(playerDocRef);

          if (playerDocSnap.exists() && playerDocSnap.data().isTeamManager) {
            setIsTeamManager(true);
          }

          const teamsQuery = query(collection(db, 'teams'), where('members', 'array-contains', user.uid));
          const teamsSnapshot = await getDocs(teamsQuery);
          const teams = teamsSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name,
            logo: doc.data().logo || '',
          }));
          setUserTeams(teams);

          const gamesPromises = teams.map(async team => {
            const gamesQuery = query(
              collection(db, 'games'),
              where('teamId', '==', team.id),
              orderBy('date', 'asc')
            );
            const gamesSnapshot = await getDocs(gamesQuery);
            return gamesSnapshot.docs.map(doc => ({
              id: doc.id,
              name: doc.data().gameTitle || formatDate(doc.data().date),
              date: doc.data().date,
              time: doc.data().time,
              location: doc.data().location,
              teamName: team.name,
              teamLogo: team.logo,
              numberOfPlayers: doc.data().numberOfPlayers,
              registeredPlayers: doc.data().registeredPlayers || [],
            }));
          });

          const games = (await Promise.all(gamesPromises)).flat();
          games.sort((a, b) => new Date(a.date) - new Date(b.date));
          setUserGames(games);
        }
      } catch (error) {
        console.error('Error fetching user or game data:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchUserData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const suffix = day === 1 || day === 21 || day === 31 ? 'st' : day === 2 || day === 22 ? 'nd' : day === 3 || day === 23 ? 'rd' : 'th';
    return `${day}${suffix} ${month} ${year}`;
  };

  if (loading) {
    // Skeleton loading state
    return (
      <div>
        <h2>Welcome to the Dashboard!</h2>
        <h3>Loading...</h3>
        {[...Array(3)].map((_, i) => (
          <div key={i} style={{ marginBottom: '20px' }}>
            <SkeletonCircle size="40px" />
            <Skeleton width="60%" height="20px" style={{ marginTop: '10px' }} />
            <Skeleton width="80%" height="20px" style={{ marginTop: '5px' }} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <h2>Welcome to the Dashboard!</h2>

      {/* Primary CTAs if the user is not part of a team */}
      {userTeams.length === 0 ? (
        <div>
          <h3>You’re not part of a team yet!</h3>
          <p>To get started, you can:</p>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Link to="/team-registration">
              <button>Create a Team</button>
            </Link>
            <Link to="/join-team">
              <button>Join a Team</button>
            </Link>
          </div>
        </div>
      ) : (
        <>
          {/* Normal dashboard if the user is part of a team */}
          {isTeamManager && (
            <Link to="/team-registration">
              <button>Create a Team</button>
            </Link>
          )}
          {userTeams.length > 0 && (
            <Link to="/create-game">
              <button>Create a Game</button>
            </Link>
          )}

          <h3>Your Games</h3>
          {userGames.length > 0 ? (
            <ul>
              {userGames.map(game => (
                <li key={game.id} style={{ marginBottom: '1rem' }}>
                  {game.teamLogo && (
                    <img
                      src={game.teamLogo}
                      alt={`${game.teamName} logo`}
                      style={{ width: '40px', height: '40px', marginRight: '8px' }}
                    />
                  )}
                  <strong>{game.teamName}</strong>
                  <br />
                  <strong>Name:</strong> {game.name}<br />
                  <strong>Date:</strong> {formatDate(game.date)}<br />
                  <strong>Time:</strong> {game.time}<br />
                  <strong>Location:</strong> {game.location}
                  <br />
                  <Link to={`/edit-game/${game.id}`}>
                    <button>Edit</button>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p>No games found.</p>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
