import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const EditGame = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  const [game, setGame] = useState({
    gameTitle: '',
    date: '',
    time: '',
    location: '',
    numberOfPlayers: '',
    status: 'Scheduled'
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const gameDocRef = doc(db, 'games', gameId);
        const gameDocSnap = await getDoc(gameDocRef);
        if (gameDocSnap.exists()) {
          setGame({
            gameTitle: gameDocSnap.data().gameTitle || '',
            date: gameDocSnap.data().date || '',
            time: gameDocSnap.data().time || '',
            location: gameDocSnap.data().location || '',
            numberOfPlayers: gameDocSnap.data().numberOfPlayers || '',
            status: gameDocSnap.data().status || 'Scheduled'
          });
        }
      } catch (error) {
        console.error('Error fetching game data:', error);
        setError('Could not load game details.');
      }
    };

    fetchGameData();
  }, [gameId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGame(prevGame => ({ ...prevGame, [name]: value }));
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const gameDocRef = doc(db, 'games', gameId);
      await updateDoc(gameDocRef, game);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating game:', error);
      setError('Failed to update game. Please try again.');
    }
  };

  return (
    <div>
      <h2>Edit Game</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSaveChanges}>
        <input
          type="text"
          name="gameTitle"
          placeholder="Game Title"
          value={game.gameTitle}
          onChange={handleInputChange}
        />
        <input
          type="date"
          name="date"
          value={game.date}
          onChange={handleInputChange}
          required
        />
        <input
          type="time"
          name="time"
          value={game.time}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="location"
          placeholder="Location"
          value={game.location}
          onChange={handleInputChange}
          required
        />
        <input
          type="number"
          name="numberOfPlayers"
          placeholder="Number of Players"
          value={game.numberOfPlayers}
          onChange={handleInputChange}
          required
        />
        <label>
          Status:
          <select name="status" value={game.status} onChange={handleInputChange}>
            <option value="Scheduled">Scheduled</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </label>
        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default EditGame;
