import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const CreateGame = () => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [location, setLocation] = useState('');
  const [status, setStatus] = useState('Scheduled'); // Default status
  const [teamId, setTeamId] = useState('');
  const [userTeams, setUserTeams] = useState([]);
  const [numberOfPlayers, setNumberOfPlayers] = useState(''); // New field for number of players
  const [gameTitle, setGameTitle] = useState(''); // Optional field for game title
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserTeams = async () => {
      const user = auth.currentUser;
      if (user) {
        const teamsQuery = query(collection(db, 'teams'), where('members', 'array-contains', user.uid));
        const teamsSnapshot = await getDocs(teamsQuery);
        const teams = teamsSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
        setUserTeams(teams);
      }
    };

    fetchUserTeams();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const suffix =
      day === 1 || day === 21 || day === 31 ? 'st' : day === 2 || day === 22 ? 'nd' : day === 3 || day === 23 ? 'rd' : 'th';
    return `${day}${suffix} ${month} ${year}`;
  };

  const handleCreateGame = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const user = auth.currentUser;
      if (!user) throw new Error("You must be logged in to create a game.");
      if (!teamId) throw new Error("Please select a team.");
      if (!numberOfPlayers || isNaN(numberOfPlayers) || numberOfPlayers <= 0) {
        throw new Error("Please enter a valid number of players.");
      }

      const title = gameTitle || formatDate(date);

      // Add game data to Firestore with the current user as the first registered player
      await addDoc(collection(db, 'games'), {
        date,
        time,
        location,
        status,
        teamId,
        numberOfPlayers: parseInt(numberOfPlayers), // Store as an integer
        gameTitle: title,
        registeredPlayers: [user.uid], // Register the creator automatically
      });

      navigate('/dashboard'); // Redirect to the dashboard after creation
    } catch (error) {
      console.error('Error creating game:', error);
      setError(error.message || 'Failed to create game. Please try again.');
    }
  };

  return (
    <div>
      <h2>Create a Game</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleCreateGame}>
        <input
          type="text"
          placeholder="Game Title (optional)"
          value={gameTitle}
          onChange={(e) => setGameTitle(e.target.value)}
        />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />
        <input
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Number of Players"
          value={numberOfPlayers}
          onChange={(e) => setNumberOfPlayers(e.target.value)}
          required
        />
        <label>
          Status:
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="Scheduled">Scheduled</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </label>
        <label>
          Team:
          <select value={teamId} onChange={(e) => setTeamId(e.target.value)} required>
            <option value="" disabled>Select a team</option>
            {userTeams.map((team) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </select>
        </label>
        <button type="submit">Create Game</button>
      </form>
    </div>
  );
};

export default CreateGame;
